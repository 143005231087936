import { Global, SerializedStyles } from "@emotion/react";
import jsCookie from "js-cookie";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import tw, { GlobalStyles, theme } from "twin.macro";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { Seo } from "./Seo";

interface Props {
  altHeader?: boolean;
  altFooter?: boolean;
  title?: string;
  ogTitle?: string;
  author?: string;
  ogImage?: string;
  ogDescription?: string;
  ogURL?: string;
  css?: SerializedStyles | string;
}

const global = {
  html: {
    fontFamily: theme`fontFamily.body`,
    color: theme`colors.turquoise.dark`
  },
  body: {
    animation: theme`animations.fade-4`
  }
};

const HeaderWrapper = tw.div`border-b w-full bg-white fixed top-0 z-20`;

export const Layout: React.FC<Props> = ({
  children,
  altHeader,
  altFooter,
  title,
  ogTitle,
  author,
  ogImage,
  ogDescription,
  ogURL,
  css,
  ...rest
}) => {
  useEffect(() => {
    let cookieScriptConsent = JSON;
    if (jsCookie.get("CookieScriptConsent") !== undefined) {
      cookieScriptConsent = JSON.parse(jsCookie.get("CookieScriptConsent") || "");
      // Ignore warning here, `.action` is a JSON property within `cookieScriptConsent` variable
      if (cookieScriptConsent.action === "accept") {
        const scripts = document.querySelectorAll("[data-third-party='true']");
        Array.from(scripts).forEach(s => {
          const newScript = document.createElement("script");
          if ((s.attributes as any).src) {
            newScript.setAttribute("src", (s.attributes as any).src.value);
          }

          newScript.setAttribute("type", "text/javascript");

          newScript.innerHTML = s.innerHTML;
          s.parentNode?.removeChild(s);
          document.body.appendChild(newScript);
        });
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="script-src 'self' https://cdn.cookie-script.com https://stats.triangular-pod.com youtube.com www.youtube.com http://cdn.cookie-script.com stats.triangular-pod.com http://stats.triangular-pod.com https://stats.triangular-pod.com; frame-src https://triangular-pod.com https://dev.triangular-pod.com https://staging.triangular-pod.com https://triangular-development-uploads.s3.eu-central-1.amazonaws.com https://triangular-staging-uploads.s3.eu-central-1.amazonaws.com https://triangular-production-uploads.s3.eu-central-1.amazonaws.com youtube.com https://www.youtube.com;"
        />
        ,
        <meta name="referrer" content="same-origin" />
      </Helmet>
      <Global styles={global} />
      <GlobalStyles />
      <Seo title={title} ogTitle={ogTitle} author={author} ogImage={ogImage} ogDescription={ogDescription} ogURL={ogURL} />
      <div tw="mt-20 sm:mt-24">
        <HeaderWrapper>
          <Header altHeader={altHeader} />
        </HeaderWrapper>
      </div>
      <main tw="overflow-x-hidden" {...rest}>
        {children}
      </main>
      <Footer altfooter={altFooter} />
    </>
  );
};
