import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro";

import MenuOpen from "../../../static/icons/menu-open.svg";
import Menu from "../../../static/icons/menu.svg";
import Logo from "../../../static/images/logo-triangular-small.svg";
import Phone from "../../../static/images/phone.svg";
import Anchor from "../../components/Anchor";
import { useBlogUrl, useLoginUrl } from "../../utils/common";
import { Container } from "../Container";
import { Register } from "../Register";
import { Languages } from "./Languges";

interface Props {
  altHeader?: boolean;
}

const Root = styled.header(({ isScroll }: { isScroll: boolean }) => [
  tw`transition-all duration-300`,
  isScroll ? tw`py-0` : tw`py-2`,
  {
    "> a": [tw`transition-all duration-300`],
    "a[href^='tel:']": [tw`transition-all duration-300`]
  }
]);
const Ul = styled.ul(({ isOpen, isScroll }: { isOpen: boolean; isScroll: boolean }) => [
  tw`ml-auto items-center text-center whitespace-nowrap`,
  tw`transition duration-300`,
  isOpen ? tw`block` : tw`hidden`,
  isOpen && [tw`shadow-lg bg-white bg-opacity-90 absolute inset-x-0 z-10 py-4`, `backdrop-filter: blur(3px)`],
  isScroll ? tw`top-20 -mt-px` : tw`top-20`,
  {
    li: tw`font-bold uppercase mb-4`,
    a: tw`hover:text-turquoise`,
    "a[id='whiteButtonText']": [tw`text-white hover:(text-white)`]
  }
]);

export const Header: React.FC<Props> = ({ altHeader }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const { t } = useTranslation();
  const blog = useBlogUrl();
  const login = useLoginUrl();

  const flags = altHeader
    ? css`
        ${tw`inline-block`}
      `
    : css`
        ${tw`hidden sm:inline-block`}
      `;

  const toggle = () => setIsOpen(!isOpen);
  const hamburguerMenu = (
    <>
      <button tw="text-grey-dark mr-2 all-child:(w-8 inline ml-2)" onClick={toggle}>
        {isOpen ? <MenuOpen /> : <Menu />}
      </button>

      <Ul isOpen={isOpen} isScroll={isScroll}>
        <li tw="sm:hidden block">
          <Languages />
        </li>
        <li>
          <Link to="/about" activeStyle={tw`text-turquoise`}>
            {t("nav.about")}
          </Link>
        </li>
        <li>
          <a href={blog} rel="dofollow">
            {t("nav.blog")}
          </a>
        </li>
        <li>
          <Link to="/benefits" activeStyle={tw`text-turquoise`}>
            {t("benefits.header")}
          </Link>
        </li>
        <li>
          <Register id="whiteButtonText" />
        </li>
        <li>
          <Anchor id="whiteButtonText" isPrimary={true} href={login}>
            {t("nav.login")}
          </Anchor>
        </li>
      </Ul>
    </>
  );

  useEffect(() => {
    const onScroll = (e: Event) => {
      setIsScroll((e.target as HTMLDocument).documentElement.scrollTop > 50);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const logo = !altHeader ? (
    <Link to="/" tw="w-48 sm:w-64 inline-grid">
      <Logo tw="justify-self-center" />
    </Link>
  ) : (
    <Logo tw="justify-self-center" />
  );
  return (
    <Container>
      <Root isScroll={isScroll} tw="relative">
        <div tw="relative flex justify-between h-20 items-center content-center">
          <a href="tel:+4982140821442" tw="flex font-bold text-lg">
            <Phone tw="hover:animate-bounce" /> <p tw="pl-3 py-2 text-black hidden md:block whitespace-nowrap">+49 821 4082 1442</p>
          </a>
          <div tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-3">{logo}</div>
          <div>
            {!altHeader && hamburguerMenu}
            <Languages css={flags} />
          </div>
        </div>
      </Root>
    </Container>
  );
};
