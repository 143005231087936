import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import { useRegistrationUrl } from "../utils/common";
import Anchor from "./Anchor";

interface Props {
  isOutlined: boolean;
  isBig: boolean;
  label: string;
  id?: string;
}

export const Register: React.FC<Partial<Props>> = ({ id, isBig, isOutlined, label, ...rest }) => {
  const { t } = useTranslation();
  const registration = useRegistrationUrl();

  return (
    <Anchor {...rest} id={id} isBig={isBig} isSecondary={!isOutlined} isOutlined={isOutlined} href={registration}>
      {t(label || "nav.register")}
    </Anchor>
  );
};
