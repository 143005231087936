import deFlag from "../../static/images/flags/de-flag.png";
import esFlag from "../../static/images/flags/es-flag.png";
import gbFlag from "../../static/images/flags/gb-flag.png";
// import plFlag from "../../static/images/flags/pl-flag.png";

export const languageOptions = [
  {
    value: "en" as const,
    label: "English",
    flag: gbFlag
  },
  {
    value: "de" as const,
    label: "Deutsch",
    flag: deFlag
  },
  {
    value: "es" as const,
    label: "Español",
    flag: esFlag
  }
  // {
  //   value: "pl" as const,
  //   label: "Polski",
  //   flag: plFlag
  // }
];

export type AppLanguage = ArrayElement<typeof languageOptions>["value"];
