import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  ogTitle?: string;
  author?: string;
  ogImage?: string;
  ogDescription?: string;
  ogURL?: string;
}

export const Seo: React.FC<Props> = ({ title, ogTitle, author, ogImage, ogDescription, ogURL }) => {
  const { i18n } = useTranslation();
  const url = "https://" + ogURL;
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          pathPrefix
          siteMetadata {
            title
            description
            language
            author
            ogImage
            url
            keywords
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language || site.siteMetadata.language
      }}
      titleTemplate={`${site.siteMetadata.title} - %s`}
      defaultTitle={title ? title : site.siteMetadata.title}
      defer={false}
    >
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="og:type" content="website" />
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="author" content={author ? author : site.siteMetadata.author} />
      <meta name="keywords" content={site.siteMetadata.keywords} />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={ogTitle ? ogTitle : site.siteMetadata.title} />
      <meta property="og:image" content={ogImage ? ogImage : site.siteMetadata.ogImage} />
      <meta property="og:description" content={ogDescription ? ogDescription : site.siteMetadata.ogImage} />
      <meta property="og:url" content={ogURL ? url : site.siteMetadata.url} />
      <meta property="twitter:card" content={"summary"} />
      <meta property="twitter:title" content={ogTitle ? ogTitle : site.siteMetadata.title} />
      <meta property="twitter:description" content={ogDescription ? ogDescription : site.siteMetadata.ogImage} />
      <meta property="twitter:image" content={ogImage ? ogImage : site.siteMetadata.ogImage} />
      <link rel="canonical" href="https://www.triangular-pod.com" />
    </Helmet>
  );
};
