import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import tw, { css } from "twin.macro";

import { languageOptions } from "../consts/languages";

interface Props {
  onlySymbols?: boolean;
}

export const LanguageList: React.FC<Props> = ({ onlySymbols }) => {
  const { i18n, originalPath } = useI18next();

  return (
    <>
      {languageOptions.map(
        ({ value, label, flag }) =>
          value !== i18n.language && (
            <li key={value} css={tw`flex`}>
              <Link
                to={originalPath}
                language={value}
                css={[
                  tw`uppercase w-24`,
                  css`
                    display: inherit;
                  `
                ]}
              >
                {onlySymbols && <img src={flag} alt="" width="16" height="12" css={tw`mr-4 mt-2 mb-1 h-3`} />}
                {onlySymbols ? value : label}
              </Link>
            </li>
          )
      )}
    </>
  );
};
