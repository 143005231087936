import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";

import { languageOptions } from "../../consts/languages";
import { LanguageList } from "../LanguageList";

import DropdownSvg from "../../../static/images/arrow-down.svg";

const Ul = tw.ul`origin-top-right absolute right--3 top-9 w-24 py-2 px-5 shadow-md bg-white z-10 border-grey-light border all-child:text-grey-dark`;
const transitionStyle = css`
  transition: transform 0.25s, margin 0.25s;
`;

export const Languages: React.FC = ({ ...rest }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const { i18n, originalPath } = useI18next();

  const dropDownStyles = isOpen
    ? css`
        transform: rotateZ(180deg);
        margin-top: 8px;
      `
    : css`
        transform: rotateZ(0deg);
        margin-top: 2px;
      `;

  const toggle = () => setIsOpen(!isOpen);

  const handleClickOutside = (e: MouseEvent) => {
    if (!ref.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={ref} tw="relative inline-block text-left" {...rest}>
      <button tw="focus:outline-none" type="button" id="options-menu" aria-haspopup="true" aria-expanded="true" onClick={toggle}>
        <ul>
          {languageOptions.map(
            ({ value, flag }) =>
              value === i18n.language && (
                <li css={tw`flex`} key={value}>
                  <img src={flag} alt="" width="16" height="12" css={tw`mr-2 mt-1.5 mb-1 h-3`} />
                  <Link
                    to={originalPath}
                    language={value}
                    css={[tw`uppercase`, value === i18n.language && tw`text-turquoise-dark font-bold`]}
                  >
                    {value}
                  </Link>
                  <DropdownSvg css={[transitionStyle, tw`ml-1`, dropDownStyles]} />
                </li>
              )
          )}
        </ul>
      </button>

      {isOpen && (
        <Ul role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <LanguageList onlySymbols={true} />
        </Ul>
      )}
    </div>
  );
};
