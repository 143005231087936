import { Link as GLink } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw from "twin.macro";

import FaceBook from "../../../static/images/facebook_logo.svg";
import Instagram from "../../../static/images/instagram_logo.svg";
import LinkedIn from "../../../static/images/linkedin.svg";
import Logo from "../../../static/images/logo-triangular-small.svg";
import Youtube from "../../../static/images/youTube_logo.svg";
import { Container } from "../Container";

interface Props {
  altfooter?: boolean;
}

const Grid = tw.footer`grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-9 xl:grid-cols-9 gap-4 pt-8 pb-0 text-sm text-grey-dark`;
const FooterLinksGroup = tw.div`lg:col-span-2`;

export const Footer: React.FC<Props> = ({ altfooter }) => {
  const { t, i18n } = useTranslation();
  const footerGrid = (
    <Grid>
      <Logo tw="w-32 h-36 order-4 sm:order-1" />
      <FooterLinksGroup tw="order-1 sm:order-2">
        <ul>
          <li tw="font-bold">TRIANGULAR-PST GMBH & CO. KG</li>
          <li>Bahnhofstraße 35</li>
          <li>86316 Friedberg</li>
          <li>Germany</li>
          <li>
            Tel.:<a href="+49 821 4082 1442">+49 821 4082 1442</a>
          </li>
        </ul>
      </FooterLinksGroup>

      <FooterLinksGroup tw="order-2 sm:order-3">
        <h4 tw="font-bold">{t("footer.company.header")}</h4>
        <ul>
          <li>
            <Link to="/about">{t("footer.company.aboutUs")}</Link>
          </li>
          <li>
            <Link to="/contact/">{t("footer.company.contactUs")}</Link>
          </li>
          <li>
            <a href="https://blog.triangular-pod.com/" rel="dofollow">
              {t("footer.company.blog")}
            </a>
          </li>
          <li>
            <a href="https://app.triangular-pod.com/registration/profile-type" rel="dofollow">
              {t("footer.company.register")}
            </a>
            <li>
              <a href="https://app.triangular-pod.com/login" rel="dofollow">
                {t("footer.company.signIn")}
              </a>
            </li>
          </li>
        </ul>
      </FooterLinksGroup>

      <FooterLinksGroup tw="order-3 sm:order-4">
        <h4 tw="font-bold">{t("footer.solutions.header")}</h4>
        <ul>
          <li>
            <Link to="/material-owners">{t("footer.solutions.materialOwners")}</Link>
          </li>
          <li>
            <Link to="/equipment-owners">{t("footer.solutions.systemOwners")}</Link>
          </li>
          <li>
            <Link to="/experts">{t("footer.solutions.industryExperts")}</Link>
          </li>
          <li>
            <Link to="/material-sourcing">{t("footer.solutions.materialSourcing")}</Link>
          </li>
        </ul>
      </FooterLinksGroup>
      <FooterLinksGroup tw="col-start-3 order-last sm:(order-6 col-start-auto ml-3)">
        <div tw="mb-3">
          <a href="https://www.linkedin.com/company/triangular-pod/">
            <LinkedIn tw="h-9 w-9 inline-block mr-2" />
          </a>
          <a href="https://www.youtube.com/channel/UCHTlFod8DMwwdRr-LVbEXJA">
            <Youtube tw="h-9 w-9 inline-block" />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/triangularpod/">
            <Instagram tw="h-9 w-9 inline-block mr-2" />
          </a>
          <a href="https://www.facebook.com/Triangular-POD-110837231253262/">
            <FaceBook tw="h-9 w-9 inline-block mr-2" />
          </a>
        </div>
      </FooterLinksGroup>
    </Grid>
  );
  return (
    <>
      <Container>
        {!altfooter && footerGrid}
        <div tw="flex flex-wrap justify-center sm:max-w-3xl mx-1 sm:mx-auto text-center mb-6 pt-4">
          <span tw="mx-3">Copyright © Triangular-PST 2021. All Rights Reserved</span>|
          <GLink tw="mx-3 hover:underline" to={`/disclaimers/${i18n.language}/terms-of-use`}>
            {t("footer.termsOfUse")}
          </GLink>
          |
          <GLink tw="mx-3 hover:underline" to={`/disclaimers/${i18n.language}/privacy-policy`}>
            {t("footer.privacyPolicy")}
          </GLink>
          |
          <GLink tw="mx-3 hover:underline" to={`/disclaimers/${i18n.language}/imprint`}>
            {t("footer.imprint")}
          </GLink>
        </div>
      </Container>
    </>
  );
};
